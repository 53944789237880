import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CivilityEnum } from '@models/worksiteCreation/utils/enums';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import { FormAddress } from '@components/formAddress/FormAddress';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { getEtablissementData } from '@models/sirene/apiRequests/sireneRequests';
import { convertToFormAddressDatas } from '@utils/utils';
import { CompanyActivityStatusEnum, OperationTypeEnum } from '@utils/enums';
import { DEFAULT_BENEFICIARY } from '../../../../../beneficiaries/utils/beneficiariesConstants';

interface IFormContentCreateBeneficiary {
  beneficiaryCivility: { civility: string; value: number };
  setBeneficiaryCivility: Dispatch<
    SetStateAction<{ civility: string; value: number }>
  >;
  setBeneficiaryAddress: Dispatch<
    SetStateAction<IBeneficiaryAddress | undefined>
  >;
  valuesForm: FieldValues;
  setValue: UseFormSetValue<FieldValues>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function FormContentCreateBeneficiary({
  beneficiaryCivility,
  setBeneficiaryCivility,
  setBeneficiaryAddress,
  valuesForm,
  setValue,
  isLoading,
  setIsLoading,
}: IFormContentCreateBeneficiary) {
  const { t } = useTranslation();

  const {
    worksiteOperationType,
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
  } = useContext(WorksiteCreationContext);

  const [valueCivility, setValueCivility] = useState<string>(
    CivilityEnum.MR.toString()
  );

  const [isManualAddress, setIsManualAddress] = useState(false);
  const [valueAddress, setValueAddress] = useState<IBeneficiaryAddress>(
    DEFAULT_BENEFICIARY.address
  );
  const [validSiretGetted, setValidSiretGetted] = useState<boolean>(false);

  const { numberStreet, streetName, zipCode, city, country } = worksiteAddress;

  const resetInformations = () => {
    setValidSiretGetted(false);
    setValue('company_name', '');
    setValue('address', '');
    setValue('id', '');
    setValue('siren', '');
    setValue('legal_category', '');
    setValue('zipcode', '');
    setValue('city', '');
    setValue('entity_to_id', '');
  };

  const onSiretChange = async (value: string) => {
    resetInformations();
    if (value.length === 14) {
      setIsLoading(true);
      const response = await getEtablissementData(
        value,
        setIsLoading,
        setValue
      );
      if (response) {
        setValidSiretGetted(true);
      }
    }
  };

  useEffect(() => {
    setValue('numberStreet', numberStreet);

    let addressValue =
      numberStreet !== '' ? `${numberStreet} ${streetName}` : streetName;

    if (streetName === '') addressValue = '';

    setValue('address', addressValue);
    setValue('street', streetName);
    setValue('zipcode', zipCode);
    setValue('city', city);
    setValue('country', country);
  }, [city, streetName, zipCode, numberStreet, country]);

  useEffect(() => {
    if (valueCivility === CivilityEnum.MRS.toString()) {
      setBeneficiaryCivility({
        civility: t('forms.civility.mrs'),
        value: 1,
      });
    } else {
      setBeneficiaryCivility({
        civility: t('forms.civility.mr'),
        value: 2,
      });
    }
  }, [valueCivility]);

  useEffect(() => {
    setBeneficiaryAddress(valueAddress);
  }, [valueAddress]);

  return (
    <div className="flex flex-col space-y-[.5rem]  mt-[1rem]">
      {worksiteOperationType !== OperationTypeEnum.B2B && (
        <div className="flex space-x-[.5rem] w-full">
          <TextWithRadio
            label={t('forms.civility.mr')}
            value={CivilityEnum.MR.toString()}
            setSelectedValue={setValueCivility}
            isChecked={beneficiaryCivility.value === CivilityEnum.MR + 1}
            selectValue={beneficiaryCivility.civility}
            addClass="w-full"
          />
          <TextWithRadio
            label={t('forms.civility.mrs')}
            value={CivilityEnum.MRS.toString()}
            setSelectedValue={setValueCivility}
            isChecked={beneficiaryCivility.value === CivilityEnum.MRS + 1}
            selectValue={beneficiaryCivility.civility}
            addClass="w-full"
          />
        </div>
      )}
      <div className="flex space-x-[.5rem] w-full">
        {worksiteOperationType === OperationTypeEnum.B2B ? (
          <>
            <InputText
              id="siret"
              name="siret"
              placeholder={t('partners.siret')}
              required
              dataTestId="input_siret"
              typeNumber
              valid={valuesForm.siret && valuesForm.siret.length === 14}
              rules={{
                pattern: {
                  value: /^\d{14}$/,
                  message: t('forms.siret.error'),
                },
              }}
              error={
                valuesForm.status &&
                valuesForm.status !== CompanyActivityStatusEnum.ACTIVE
              }
              textError={
                valuesForm.status !== CompanyActivityStatusEnum.ACTIVE
                  ? t('forms.siret.error_invalid_status', {
                      company_name: valuesForm.company_name,
                    }) || ''
                  : undefined
              }
              maxLength={14}
              onChange={(value) => onSiretChange(String(value))}
              loading={isLoading}
              spinnerLoader
            />
            <InputText
              id="company_name"
              name="company_name"
              placeholder={t('partners.company_name')}
              required
              dataTestId="input_company_name"
              disabled={!validSiretGetted}
              loading={isLoading}
              spinnerLoader
            />
          </>
        ) : (
          <>
            <InputText
              id="firstname"
              name="firstname"
              placeholder={t('forms.firstname.placeholder')}
              required
              valid={valuesForm.firstname !== ''}
              dataTestId="input_first_name"
            />
            <InputText
              id="lastname"
              name="lastname"
              placeholder={t('forms.lastname.placeholder')}
              required
              valid={valuesForm.lastname !== ''}
              dataTestId="input_last_name"
            />
          </>
        )}
      </div>
      {worksiteOperationType === OperationTypeEnum.B2B ? (
        <>
          <InputText
            id="address"
            name="address"
            placeholder={t('worksites.beneficiary.address')}
            disabled={!validSiretGetted}
            value={valuesForm.address || ''}
            loading={isLoading}
            spinnerLoader
            dataTestId="input_address_disabled"
          />
          <div className="flex space-x-2 w-full">
            <InputText
              id="zipcode"
              name="zipcode"
              typeNumber
              maxLength={5}
              placeholder={t('worksites.beneficiary.zip_code')}
              disabled={!validSiretGetted}
              value={valuesForm.zipcode || ''}
              loading={isLoading}
              spinnerLoader
              dataTestId="input_zipcode_disabled"
            />
            <InputText
              id="city"
              name="city"
              placeholder={t('worksites.beneficiary.city')}
              disabled={!validSiretGetted}
              value={valuesForm.city || ''}
              loading={isLoading}
              spinnerLoader
              dataTestId="input_address_disabled"
            />
          </div>
        </>
      ) : (
        <FormAddress
          setIsManual={setIsManualAddress}
          addressDatas={valueAddress}
          setAddressDatas={setValueAddress}
          isManual={isManualAddress}
          datas={convertToFormAddressDatas(worksiteAddress)}
          updateDatas={updateWorksiteAddressFromKeyValue}
          showLabel
          required
        />
      )}
      <InputText
        id="phone"
        name="phone"
        placeholder={t('forms.phone.placeholder')}
        required
        valid={phoneNumberRegex.test(valuesForm.phone)}
        dataTestId="input_phone"
        error={!phoneNumberRegex.test(valuesForm.phone || '0102030405')}
      />
      <InputText
        id="phoneFix"
        name="phoneFix"
        placeholder={t('forms.phoneFix.placeholder')}
        valid={phoneNumberRegex.test(valuesForm.phoneFix)}
        error={!phoneNumberRegex.test(valuesForm.phoneFix || '0102030405')}
      />
      <InputText
        id="email"
        name="email"
        placeholder={t('forms.email.placeholder')}
        required
        valid={emailRegex.test(valuesForm.email)}
        dataTestId="input_email"
      />
    </div>
  );
}

export { FormContentCreateBeneficiary };
